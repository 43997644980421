import { makeObservable, observable, action } from 'mobx';

const _init = {
    show: false,
    vertical: 'top',
    horizontal: 'center',
    duration: 1500,
    type: 'success',
    message: ''
};

export default class NotificationService {
    init = {
        show: false,
        vertical: 'top',
        horizontal: 'center',
        duration: 1500,
        type: 'success',
        message: ''
    };

    constructor() {
        makeObservable(this, {
            init: observable,

            setNotify: action,
            clearNotify: action
        });
    }

    setNotify(data) {
        this.init = { ..._init, ...data, show: true };
    }

    clearNotify() {
        this.init = {
            ...this.init,
            show: false
        };
    }
}
