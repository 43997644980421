// material
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
// components
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import FaqTable from '../components/portal/faq/FaqTable';
import Breadcrumbs from '../components/BreadCrumbs';
import CardBack from '../components/CardBack';

// ----------------------------------------------------------------------

export default function Faqs() {
    return (
        <Page title="คำถามที่พบบ่อย">
            <Container maxWidth="xl">
                <Breadcrumbs data={[{ name: 'คำถามที่พบบ่อย' }]} />

                <MHidden width="smUp">
                    <Box sx={{ mt: 3 }}>
                        <CardBack />
                    </Box>

                    <Divider sx={{ my: 3 }} />
                </MHidden>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FaqTable />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
