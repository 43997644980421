import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

ContentDetail.propTypes = {
    title: PropTypes.string,
    detail: PropTypes.any
};

export default function ContentDetail({ title, detail = '-' }) {
    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography
                component="span"
                variant="body2"
                sx={{ opacity: 0.72, whiteSpace: 'pre-wrap' }}
            >
                {detail ?? '-'}
            </Typography>
        </>
    );
}
