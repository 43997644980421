import { useParams } from 'react-router-dom';
// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../components/Page';
import RequestTable from '../components/portal/request/RequestTable';
import Breadcrumbs from '../components/BreadCrumbs';

// ----------------------------------------------------------------------

export default function Requests() {
    const { type } = useParams();
    const title = `รายการคำร้อง (${type === 'user_request' ? 'User Request' : 'Incident'})`;

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <Breadcrumbs
                    data={[
                        {
                            name: title
                        }
                    ]}
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <RequestTable type={type} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
