import qs from 'qs';
import http from '../utils/http';

export function authLogin(data) {
    return http.post('/login', qs.stringify(data));
}

export function authME() {
    return http.get('/profile');
}

export function authLogout() {
    localStorage.removeItem('token');
}

export function getToken() {
    return null;
    // const tokenString = localStorage.getItem('token');
    // const userToken = JSON.parse(tokenString);
    // return userToken ?? null;
}

export function setToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
}

export function updateProfile(data) {
    return http.post('/profile', qs.stringify(data));
}

export function updateProfilePic(data) {
    return http.post('/profile', data);
}
