import { observer } from 'mobx-react-lite';
import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';

import useService from './hooks/useService';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
// import NotFound from './pages/Page404';

import Home from './pages/Home';
import ServiceFamilies from './pages/ServiceFamilies';
import Services from './pages/Services';
import ServiceSubs from './pages/ServiceSubs';
import Requests from './pages/Requests';
import RequestCreate from './pages/RequestCreate';
import RequestUpdate from './pages/RequestUpdate';
import Faqs from './pages/Faqs';
import Profile from './pages/Profile';

import PreloadingPage from './components/PreloadingPage';

// ----------------------------------------------------------------------

function PrivateRouter({ isLoggedIn }) {
    return useRoutes([
        {
            path: '/portal',
            element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
            children: [
                { element: <Navigate to="/portal/home" replace /> },
                { path: 'home', element: <Home /> },
                { path: 'families', element: <ServiceFamilies /> },
                { path: 'families/:familyId/services', element: <Services /> },
                {
                    path: 'families/:familyId/services/:serviceId/service_subs',
                    element: <ServiceSubs />
                },
                { path: 'requests/:type', element: <Requests /> },
                {
                    path: 'request_create',
                    element: <RequestCreate />
                },
                // {
                //     path: 'request_create/families/:familyId/services/:serviceId/service_subs/:serviceSubId',
                //     element: <RequestCreate />
                // },
                { path: 'request_update/:ticketCode', element: <RequestUpdate /> },
                { path: 'faqs', element: <Faqs /> },
                { path: 'profile', element: <Profile /> }
            ]
        },
        // {
        //     path: '/dashboard',
        //     element: <DashboardLayout />,
        //     children: [
        //         { element: <Navigate to="/dashboard/app" replace /> },
        //         { path: 'home', element: <Home /> },
        //         { path: 'app', element: <DashboardApp /> },
        //         { path: 'user', element: <User /> },
        //         { path: 'products', element: <Products /> },
        //         { path: 'blog', element: <Blog /> }
        //     ]
        // },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
                { path: '/', element: <Navigate to="/portal/home" /> },
                { path: 'login', element: <Login /> },
                { path: 'register', element: <Register /> }
                // { path: '404', element: <NotFound /> },
                // { path: '*', element: <Navigate to="/404" /> }
            ]
        }
        // { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}

function Router() {
    const { auth } = useService();

    useEffect(() => {
        setTimeout(() => {
            auth.fetchUserInfo()
                .then(() => auth.login())
                .catch(() => (window.location.href = process.env.REACT_APP_ITOP_LOGIN));
        }, 3000);
    }, []);

    if (auth.ready === false) {
        return <PreloadingPage />;
    }

    return <PrivateRouter isLoggedIn={auth.isLoggedIn} />;
}

export default observer(Router);
