import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useService from '../../hooks/useService';

const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function Notification() {
    const { notify } = useService();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        notify.clearNotify();
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                anchorOrigin={{
                    vertical: notify.init.vertical,
                    horizontal: notify.init.horizontal
                }}
                open={notify.init.show}
                autoHideDuration={notify.init.duration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={notify.init.type} sx={{ width: '100%' }}>
                    {notify.init.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

export default observer(Notification);
