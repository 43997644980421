import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// material
import { Box, Grid, Container, Typography, Divider } from '@mui/material';

// components
import { MHidden } from '../components/@material-extend';
import CardItem from '../components/CardItem';
import CardBack from '../components/CardBack';
import Breadcrumbs from '../components/BreadCrumbs';
import Page from '../components/Page';
import Preloading from '../components/PreLoading';

import { getServiceSubs } from '../services/service';
// ----------------------------------------------------------------------

export default function ServiceSubs() {
    return (
        <Page title="เพิ่มคำร้องขอรับบริการ">
            <Container maxWidth="xl">
                <Breadcrumbs
                    data={[
                        { name: 'หมวดหมู่บริการ', url: '/portal/families' },
                        { name: 'ประเภทบริการ', url: '/portal/families/1/services' },
                        { name: 'คำร้อง' }
                    ]}
                />

                <MHidden width="smUp">
                    <Box sx={{ mt: 3 }}>
                        <CardBack />
                    </Box>

                    <Divider sx={{ my: 3 }} />
                </MHidden>

                <Box sx={{ my: 3 }}>
                    <Typography align="center" variant="h5">
                        เลือกคำร้อง
                    </Typography>
                </Box>

                <RenderContent />

                {/* <Grid container spacing={3}>
                    <MHidden width="smDown">
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <CardBack />
                        </Grid>
                    </MHidden>

                    {SERVICE_SUBS.map((sub, idx) => (
                        <Grid key={idx} item xs={12} sm={6} md={3} lg={3}>
                            <CardItem
                                bgColor="#4B4B4B"
                                fontColor="#FFF"
                                {...sub}
                                url={`/portal/request_create/${idx + 1}`}
                            />
                        </Grid>
                    ))}
                </Grid> */}
            </Container>
        </Page>
    );
}

function RenderContent() {
    const { familyId, serviceId } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getList = () => {
        setLoading(true);
        getServiceSubs(familyId, serviceId)
            .then((res) => setData(res.data.data))
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Preloading />;
    }

    return (
        <Grid container spacing={3}>
            <MHidden width="smDown">
                <Grid sx={{ minHeight: '109px' }} item xs={12} sm={6} md={3} lg={3}>
                    <CardBack />
                </Grid>
            </MHidden>

            {data.length > 0 &&
                data.map((data, idx) => (
                    <Grid sx={{ minHeight: '109px' }} key={idx} item xs={12} sm={6} md={3} lg={3}>
                        <CardItem
                            image={{ ...data?.icon }}
                            bgColor="#bb3333"
                            fontColor="#fff"
                            title={data?.name}
                            subTitle={data?.description}
                            icon={data?.icon?.data}
                            url={`/portal/request_create/families/${familyId}/services/${serviceId}/service_subs/${data?.id}`}
                        />
                    </Grid>
                ))}
        </Grid>
    );
}
