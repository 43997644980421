// material
import { Card, Container } from '@mui/material';
import RequestForm from '../components/portal/request/RequestForm';
// components
import Breadcrumbs from '../components/BreadCrumbs';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function RequestCreate() {
    return (
        <Page title="เพิ่มคำร้องขอรับบริการ">
            <Container maxWidth="xl">
                {/* <Typography variant="h4" sx={{ mb: 3 }}>
                    เพิ่มคำร้องขอรับบริการ
                </Typography> */}
                <Breadcrumbs
                    data={[
                        { name: 'รายการคำร้อง', url: '/portal/requests/user_request' },
                        { name: 'เพิ่มคำร้องขอรับบริการ' }
                    ]}
                />

                <Card sx={{ p: 3 }}>
                    <RequestForm />
                </Card>
            </Container>
        </Page>
    );
}
