import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// material
import { Box, Grid, Container, Typography, Divider } from '@mui/material';

// components
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import CardItem from '../components/CardItem';
import CardBack from '../components/CardBack';
import Breadcrumbs from '../components/BreadCrumbs';
import Preloading from '../components/PreLoading';

import { getServiceFamilies, getServices } from '../services/service';
import { SERVICE_ICON } from '../utils/helpers';

// ----------------------------------------------------------------------

export default function Services() {
    return (
        <Page title="เพิ่มคำร้องขอรับบริการ">
            <Container maxWidth="xl">
                <Breadcrumbs data={[{ name: 'หมวดหมู่บริการ' }]} />

                <MHidden width="smUp">
                    <Box sx={{ mt: 3 }}>
                        <CardBack />
                    </Box>

                    <Divider sx={{ my: 3 }} />
                </MHidden>

                <Box sx={{ my: 3 }}>
                    <Typography align="center" variant="h5">
                        เลือกหมวดหมู่บริการ
                    </Typography>
                </Box>

                <RenderContent />
            </Container>
        </Page>
    );
}

function RenderContent() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getList = async () => {
        setLoading(true);
        getServiceFamilies()
            .then(async (res) => {
                const promises = [];
                const families = res.data ?? [];

                families.forEach((data) => promises.push(getService(data.id)));

                const resolved = await Promise.all(promises);
                resolved.forEach((data, i) => (families[i].count = data));
                setData(families);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    const getService = async (familyId) => {
        setLoading(true);
        try {
            const resp = await getServices(familyId, { limit: 1 });
            return resp.data?.total;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, []);

    if (data?.length === 1) {
        navigate(`/portal/families/${data[0]?.id}/services`);
    }

    if (loading) {
        return <Preloading />;
    }

    return (
        <Grid container spacing={3}>
            {data.length > 0 &&
                data.map((data, idx) => (
                    <Grid sx={{ minHeight: '109px' }} key={idx} item xs={12} sm={6} md={3} lg={3}>
                        <CardItem
                            image={data?.icon?.data ? { ...data?.icon } : { ...SERVICE_ICON }}
                            bgColor={data?.count > 0 ? '#880000' : '#4B4B4B'}
                            fontColor="#fff"
                            title={data?.name}
                            url={data?.count > 0 ? `/portal/families/${data?.id}/services` : ''}
                        />
                    </Grid>
                ))}

            {/* 4B4B4B */}

            {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                <CardItem
                    bgColor="#00ab55"
                    fontColor="#fff"
                    title="ETC"
                    subTitle="การบริการด้านอื่น ๆ"
                    icon={thermometerIcon}
                    url="/portal/families/1/services"
                />
            </Grid> */}
        </Grid>
    );
}
