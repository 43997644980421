import { Icon } from '@iconify/react';
import questionMarkFill from '@iconify/icons-eva/question-mark-fill';
import fileAddFill from '@iconify/icons-eva/file-add-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import logoutFill from '@iconify/icons-eva/log-out-fill';
import { authLogout } from '../../services/auth';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
    {
        title: 'หน้าหลัก',
        path: '/portal/home',
        icon: getIcon(homeFill)
    },
    {
        title: 'เพิ่มคำร้องขอรับบริการ',
        path: '/portal/request_create',
        icon: getIcon(fileAddFill)
    },
    {
        title: 'รายการคำร้อง (User Request)',
        path: '/portal/requests/user_request',
        icon: getIcon(fileTextFill)
    },
    {
        title: 'รายการคำร้อง (Incident)',
        path: '/portal/requests/incident',
        icon: getIcon(fileTextFill)
    },
    {
        title: 'คำถามที่พบบ่อย',
        path: '/portal/faqs',
        icon: getIcon(questionMarkFill)
    },
    {
        title: 'ออกจากระบบ',
        path: '',
        icon: getIcon(logoutFill),
        onClick: () => {
            // authLogout();
            // window.location.reload();
            window.location.href = process.env.REACT_APP_ITOP_LOGOUT;
        }
    }
    // ,
    // {
    //     title: 'dashboard',
    //     path: '/dashboard/app',
    //     icon: getIcon(pieChart2Fill)
    // },
    // {
    //     title: 'user',
    //     path: '/dashboard/user',
    //     icon: getIcon(peopleFill)
    // },
    // {
    //     title: 'product',
    //     path: '/dashboard/products',
    //     icon: getIcon(shoppingBagFill)
    // },
    // {
    //     title: 'blog',
    //     path: '/dashboard/blog',
    //     icon: getIcon(fileTextFill)
    // },
    // {
    //     title: 'login',
    //     path: '/login',
    //     icon: getIcon(lockFill)
    // },
    // {
    //     title: 'Not found',
    //     path: '/404',
    //     icon: getIcon(alertTriangleFill)
    // }
];

export default sidebarConfig;
