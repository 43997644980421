import qs from 'qs';
import http from '../utils/http';

export function createRequest(data) {
    return http.post('/tickets', qs.stringify(data));
}

export function updateRequest(ticketCode, data) {
    return http.post(`/tickets/${ticketCode}`, qs.stringify(data));
}

export function getRequest(ticketCode) {
    return http.get(`/tickets/${ticketCode}`);
}

export function createAttachments(ticketCode, data) {
    return http.post(`/tickets/${ticketCode}/attachments`, data);
}

export function getAttachments(ticketCode) {
    return http.get(`/tickets/${ticketCode}/attachments`);
}

export function downloadAttachments(ticketCode, attachmentId) {
    return http.get(`/tickets/${ticketCode}/attachments/${attachmentId}/download`);
}

export function deleteAttachments(ticketCode, attachmentId) {
    return http.delete(`/tickets/${ticketCode}/attachments/${attachmentId}`);
}
