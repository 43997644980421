import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import angleLeft from '@iconify/icons-fa-solid/angle-left';

// material
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer'
}));

// ----------------------------------------------------------------------

CardBack.propTypes = {
    bgColor: PropTypes.string,
    fontColor: PropTypes.string
};

export default function CardBack({ bgColor = '#F5F4F4', fontColor = '#1F1F1F' }) {
    const navigate = useNavigate();

    return (
        <RootStyle onClick={() => navigate(-1)} sx={{ backgroundColor: bgColor, color: fontColor }}>
            <Icon icon={angleLeft} width={20} height={20} />
            <Typography
                sx={{
                    fontWeight: 500,
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%)'
                }}
                variant="h4"
                align="center"
            >
                กลับ
            </Typography>
        </RootStyle>
    );
}
