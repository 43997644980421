import { useNavigate, useParams } from 'react-router';
// material
import { Container } from '@mui/material';
// import RequestForm from '../components/portal/request/RequestForm';
import RequestUpdateForm from '../components/portal/request/RequestUpdate';
// components
import Breadcrumbs from '../components/BreadCrumbs';
import Page from '../components/Page';
// import { useRequestUpdateModal } from '../components/portal/request/RequestUpdate';

// ----------------------------------------------------------------------

export default function RequestUpdate() {
    const navigate = useNavigate();
    const { ticketCode } = useParams();
    const type = ticketCode.includes('R') ? 'user_request' : 'incident';

    const onSubmit = () => {
        navigate('/portal/requests', { replace: true });
    };

    return (
        <Page title="อัปเดตคำร้อง">
            <Container maxWidth="xl">
                {/* <Typography variant="h4" sx={{ mb: 3 }}>
                    เพิ่มคำร้องขอรับบริการ
                </Typography> */}
                <Breadcrumbs
                    data={[
                        { name: 'รายการคำร้อง', url: `/portal/requests/${type}` },
                        {
                            name: `Updating ${ticketCode} (${
                                type === 'user_request' ? 'User Request' : 'Incident'
                            })`
                        }
                    ]}
                />

                <RequestUpdateForm onSubmit={onSubmit} />
            </Container>
        </Page>
    );
}
