import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Card,
    Button,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    CardHeader,
    Divider,
    CardContent
} from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Box } from '@mui/system';
import Scrollbar from '../../Scrollbar';
import Preloading from '../../PreLoading';

import http from '../../../utils/http';
import { getPriority } from '../../../utils/requestWords';
import { fDateFull } from '../../../utils/formatDate';

const TABLE_HEAD = [
    { id: 'fullName', name: 'รหัสคำร้อง', align: '' },
    { id: 'title', name: 'ชื่อคำร้อง', align: '' },
    { id: 'startDate', name: 'วันที่รับคำร้อง', align: '' },
    { id: 'status', name: 'สถานะ', align: '' },
    { id: 'service', name: 'บริการ', align: '' },
    { id: 'serviceSubcategory', name: 'ประเภทบริการ', align: '' },
    { id: 'priority', name: 'ระดับความสำคัญ', align: '' },
    { id: 'caller', name: 'ผู้แจ้ง', align: '' }
];

BasicTable.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string
};

export default function BasicTable({ type, title = 'รายการคำร้อง' }) {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getList = () => {
        setLoading(true);
        http.get(`/tickets?type=${type}&limit=5`)
            .then((res) => setData(res.data.data))
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, []);

    const onView = (ticketCode) => {
        navigate(`/portal/request_update/${ticketCode}`);
    };

    return (
        <Card>
            <CardHeader title={title} />

            <CardContent>
                {loading ? (
                    <Preloading />
                ) : (
                    <Scrollbar>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell key={headCell.id}>{headCell.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.map((row, idx) => (
                                        <TableRow
                                            hover
                                            key={idx}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => onView(row.ref)}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.ref}
                                            </TableCell>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{fDateFull(row.start_date)}</TableCell>
                                            <TableCell>{row.status?.toPascalCase()}</TableCell>
                                            <TableCell>
                                                {row.service_name?.toPascalCase()}
                                            </TableCell>
                                            <TableCell>
                                                {row.servicesubcategory_name?.toPascalCase()}
                                            </TableCell>
                                            <TableCell>
                                                {getPriority(row.priority)?.toPascalCase()}
                                            </TableCell>
                                            <TableCell>{row.caller_id_friendlyname}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                )}
            </CardContent>

            <Divider />

            <Box sx={{ p: 2, textAlign: 'right' }}>
                <Button
                    to={`/portal/requests/${type}`}
                    size="small"
                    color="inherit"
                    component={RouterLink}
                    endIcon={<Icon icon={arrowIosForwardFill} />}
                >
                    ดูทั้งหมด
                </Button>
            </Box>
        </Card>
    );
}
