import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';

const getIcon = (name) => <Icon icon={name} style={{ marginRight: '0.5px' }} />;

IconBreadcrumbs.propTypes = {
    data: PropTypes.array
};

export default function IconBreadcrumbs({ data = [] }) {
    if (!data.length > 0) {
        return null;
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                component={RouterLink}
                to="/portal/home"
            >
                {getIcon(homeFill)}
            </Link>
            {data.length > 0 &&
                data.map((data, idx) =>
                    data.url ? (
                        <Link
                            key={idx}
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            component={RouterLink}
                            to={data?.url}
                        >
                            {data?.name}
                        </Link>
                    ) : (
                        <Typography
                            key={idx}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            {data?.name}
                        </Typography>
                    )
                )}
        </Breadcrumbs>
    );
}
