import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import RequestStatus from './RequestStatus';
import DateRangePicker from '../../DateRangePicker';

import http from '../../../utils/http';
import { getPriority } from '../../../utils/requestWords';
import { fDateFull } from '../../../utils/formatDate';

const columns = [
    { field: 'ref', headerName: 'รหัสคำร้อง', width: 100 },
    { field: 'title', headerName: 'ชื่อคำร้อง', width: 250 },
    {
        field: 'start_date',
        headerName: 'วันที่รับคำร้อง',
        width: 200
    },
    { field: 'status', headerName: 'สถานะ', width: 100 },
    { field: 'service_name', headerName: 'บริการ', width: 200 },
    {
        field: 'servicesubcategory_name',
        headerName: 'ประเภทบริการ',
        width: 250
    },
    {
        field: 'priority',
        headerName: 'ระดับความสำคัญ',
        width: 150
    },
    {
        field: 'caller_id_friendlyname',
        headerName: 'ผู้แจ้ง',
        width: 200
    }
];

function NoRows() {
    return (
        <GridOverlay>
            <div>ไม่มีข้อมูล</div>
        </GridOverlay>
    );
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const DataTable = ({ type }) => {
    let delayTimer;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [rows, setRows] = useState([]);
    const [origin, setOrigin] = useState([]);
    // const [columns, setColumns] = useState([]);
    const [params, setParams] = useState({
        operational_status: 'ongoing',
        start_date: [dayjs().startOf('d').subtract(1, 'M').format(), dayjs().endOf('d').format()]
    });

    const handleSearch = (key, value) => {
        clearTimeout(delayTimer);

        delayTimer = setTimeout(() => {
            if (!value) {
                value = undefined;
            }
            setParams({ ...params, [key]: value });
        }, 500);
    };

    useEffect(() => {
        setLoading(true);
        http.get(`/tickets?type=${type}`, { params })
            .then((response) => {
                setRows(response.data.data);
                setOrigin(response.data.data);
            })
            .finally(() => setLoading(false));
    }, [type, params]);

    const filterRows = useCallback(
        (searchValue) => {
            setText(searchValue);
            searchValue = searchValue.trim();
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = origin.filter((row) =>
                Object.keys(row).some((field) => searchRegex.test(row[field].toString()))
            );
            // const filteredRows = origin.filter((row: any) => {
            //     return ['id', 'ref'].some((field) => searchRegex.test(row[field].toString()));
            // });
            setRows(filteredRows);
        },
        // eslint-disable-next-line
        [rows]
    );

    const onView = (ticketCode) => {
        navigate(`/portal/request_update/${ticketCode}`);
    };

    return (
        <div style={{ height: 650, width: '100%' }}>
            {columns.length > 0 ? (
                <DataGrid
                    loading={loading}
                    rows={rows?.map((data) => ({
                        ...data,
                        status: data.status?.toPascalCase(),
                        service_name: data.service_name?.toPascalCase(),
                        servicesubcategory_name: data.servicesubcategory_name?.toPascalCase(),
                        priority: getPriority(data.priority)?.toPascalCase(),
                        start_date: fDateFull(data.start_date)
                    }))}
                    columns={columns}
                    components={{
                        Toolbar: QuickSearchToolbar,
                        NoRowsOverlay: NoRows
                    }}
                    componentsProps={{
                        toolbar: {
                            value: text,
                            onChange: (event) => filterRows(event.target.value),
                            clearSearch: () => filterRows(''),
                            handleSearch,
                            datePickerValue: params.start_date
                        },
                        pagination: {
                            labelRowsPerPage: 'จำนวนข้อมูล(แถว) ต่อหน้า'
                        }
                    }}
                    onRowClick={(params) => onView(params.row?.ref)}
                    pagination
                />
            ) : null}
        </div>
    );
};

DataTable.propTypes = {
    type: PropTypes.string
};

QuickSearchToolbar.propTypes = {
    handleSearch: PropTypes.func,
    onChange: PropTypes.func,
    clearSearch: PropTypes.func,
    value: PropTypes.any,
    datePickerValue: PropTypes.any
};

function QuickSearchToolbar(props) {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <RequestStatus
                        onSearch={(value) => props.handleSearch('operational_status', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <DateRangePicker
                        initDates={props.datePickerValue}
                        onChange={(value) => props.handleSearch('start_date', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        fullWidth
                        value={props.value}
                        onChange={props.onChange}
                        placeholder="ค้นหา"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <SearchIcon fontSize="small" sx={{ paddingRight: 1 }} />
                            ),
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    sx={{ visibility: props.value ? 'visible' : 'hidden' }}
                                    onClick={props.clearSearch}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default DataTable;
