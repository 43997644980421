import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, TextField, Box } from '@mui/material';
import _ from 'lodash';

import Tree from './Tree';
import Preloading from '../../PreLoading';

import { getFaqs } from '../../../services/faq';

export default function FaqTable() {
    let delayTimer;
    const [search, setSearch] = useState(null);

    const handleSearch = (value) => {
        clearTimeout(delayTimer);

        delayTimer = setTimeout(() => {
            setSearch(value);
        }, 500);
    };

    return (
        <Card>
            <CardHeader title="รายการคำถามที่พบบ่อย" />

            <CardContent>
                <Box sx={{ mb: 2 }}>
                    <TextField
                        size="small"
                        label="ค้นหา"
                        placeholder="Title"
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </Box>

                <RenderContent search={search} />
            </CardContent>
        </Card>
    );
}

function RenderContent({ search }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getList = () => {
        setLoading(true);
        getFaqs({ limit: 100, filter: search })
            .then((res) => setData(res.data.data))
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [search]);

    const grouped = _.mapValues(_.groupBy(data, 'category_name'), (list) =>
        list.map((data) => _.omit(data, 'category_name'))
    );

    if (loading) {
        return <Preloading />;
    }

    return <Tree dataSource={grouped} />;
}

RenderContent.propTypes = {
    search: PropTypes.string
};
