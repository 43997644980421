import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container } from '@mui/material';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';

import useService from '../hooks/useService';
// import AuthSocial from '../components/authentication/AuthSocial';
import Logo from '../assets/images/itop-logo-external.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
    const navigate = useNavigate();
    const { auth } = useService();

    useEffect(() => {
        if (auth.isLoggedIn) {
            navigate('/portal/home', { replace: true });
        }
    }, [auth.isLoggedIn, navigate]);

    return (
        <RootStyle title="Gamification Support Portal">
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5 }} display="flex" direction="column" justifyContent="center">
                        {/* <img
                            src={Logo}
                            alt=""
                            style={{
                                height: '150px',
                                // width: '100%',
                                objectFit: 'contain'
                            }}
                        /> */}
                        {/* <Typography
                            align="center"
                            variant="h4"
                            sx={{
                                fontSize: {
                                    xs: '20px'
                                }
                            }}
                            gutterBottom
                        >
                            ระบบแก้ไขปัญหาและให้คำปรึกษา สำนักเลขารัฐสภา
                        </Typography> */}
                        {/* <Typography sx={{ color: 'text.secondary' }}>
                            Enter your details below.
                        </Typography> */}
                    </Stack>

                    <LoginForm />
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
