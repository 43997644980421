import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Preloading() {
    return (
        <Box display="flex">
            <CircularProgress sx={{ m: 'auto' }} color="primary" />
        </Box>
    );
}
