import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import * as Yup from 'yup';

import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
    TableRow,
    TableCell,
    FormControl,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MHidden } from '../../@material-extend';
import useService from '../../../hooks/useService';
import PublicLog from './PublicLog';
import ContentDetail from '../../ContentDetail';
import TableAllData from '../../TableAllData';
import Preloading from '../../PreLoading';
import { fDateFull } from '../../../utils/formatDate';

import useNotify from '../../../hooks/useNotify';

import { getToken } from '../../../services/auth';
import { getContacts } from '../../../services/contact';
import {
    getRequest,
    updateRequest,
    getAttachments,
    createAttachments,
    deleteAttachments,
    downloadAttachments
} from '../../../services/request';
import { getImpact, getPriority, getUrgency, getSatis } from '../../../utils/requestWords';

function Content() {
    const { ticketCode } = useParams();
    const notify = useNotify();
    const [upload, setUpload] = useState(false);

    const state = useLocalObservable(() => ({
        reload: false,
        loading: false,
        data: null,
        contactList: [],
        attachmentList: [],
        fetchRequest() {
            state.setLoading(true);
            getRequest(ticketCode)
                .then((res) => {
                    runInAction(() => {
                        state.data = res.data;
                    });
                })
                .finally(() => state.setLoading(false));
        },
        fetchContactList() {
            getContacts().then((res) => {
                runInAction(() => {
                    const arr = res.data.data
                        ?.filter(
                            (data) => data?.status === 'active' && !data?.function?.includes('LINE')
                        )
                        ?.map((data) => ({ label: data.friendlyname, id: data.id }));
                    state.contactList = arr;
                });
            });
        },
        fetchAttachmentList() {
            state.setLoading(true);
            getAttachments(ticketCode)
                .then((res) => {
                    runInAction(() => {
                        state.attachmentList = res.data;
                    });
                })
                .finally(() => state.setLoading(false));
        },
        setLoading(loading) {
            state.loading = loading;
        },
        setReload() {
            state.reload = !state.reload;
        }
    }));

    useEffect(() => {
        async function fetchData() {
            await state.fetchRequest();
            // await state.fetchAttachmentList();
        }

        fetchData();
        // eslint-disable-next-line
    }, [state.reload]);

    useEffect(() => {
        state.fetchContactList();
        // eslint-disable-next-line
    }, []);

    const onUpload = (file) => {
        setUpload(true);
        const formData = new FormData();
        formData.append('attachment', file);
        createAttachments(ticketCode, formData)
            .then(() => {
                notify({ message: 'อัปโหลดไฟล์สำเร็จ' });
                state.setReload();
            })
            .catch(() => notify({ type: 'error', message: 'อัปโหลดไฟล์ไม่สำเร็จ' }))
            .finally(() => setUpload(false));
    };

    const onDownload = (attachmentId) => {
        downloadAttachments(ticketCode, attachmentId)
            .then(() => {
                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', fileName);
                // link.click();
            })
            .catch(() => notify({ type: 'error', message: 'ดาวน์โหลดไฟล์ไม่สำเร็จ' }));
    };

    const onDeleteFile = (attachmentId) => {
        deleteAttachments(ticketCode, attachmentId)
            .then(() => {
                notify();
                state.setReload();
            })
            .catch(() => notify({ type: 'error', message: 'ลบไฟล์ไม่สำเร็จ' }));
    };

    const { data, contactList, attachmentList, setReload } = state;

    return (
        <>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item lg={12} xl={7} sx={{ width: '100%' }}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">ข้อมูลคำร้อง</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ContentDetail title="ชื่อคำร้อง" detail={data?.title} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ContentDetail
                                        title="ผู้แจ้ง"
                                        detail={data?.caller_id_friendlyname}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ContentDetail
                                        title="บริการ"
                                        detail={data?.service_name?.toPascalCase()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ContentDetail
                                        title="ประเภทบริการ"
                                        detail={data?.servicesubcategory_name?.toPascalCase()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContentDetail
                                        title="รายละเอียด"
                                        detail={ReactHtmlParser(data?.description)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mt={0}>
                                {data?.solution && (
                                    <Grid item xs={12}>
                                        <ContentDetail
                                            title="วิธีแก้ปัญหา"
                                            detail={ReactHtmlParser(data?.solution)}
                                        />
                                    </Grid>
                                )}
                                {data?.operational_status === 'closed' && (
                                    <>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <ContentDetail
                                                title="ความพิงพอใจในการแก้ปัญหา"
                                                detail={getSatis(
                                                    data?.user_satisfaction
                                                )?.toPascalCase()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <ContentDetail
                                                title="ความคิดเห็น"
                                                detail={ReactHtmlParser(data?.user_comment)}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={12} xl={5} sx={{ width: '100%' }}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">สถานะคำร้อง</Typography>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid container item xs={12} sm={6} spacing={2}>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="สถานะ"
                                                detail={data?.status?.toPascalCase()}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="ผลกระทบ"
                                                detail={getImpact(data?.impact)?.toPascalCase()}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="ระดับความเร่งด่วน"
                                                detail={getUrgency(data?.urgency)?.toPascalCase()}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="ระดับความสำคัญ"
                                                detail={getPriority(data?.priority)?.toPascalCase()}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} spacing={2}>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="วันที่รับคำร้อง"
                                                detail={fDateFull(data?.start_date)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="วันที่แก้ไขล่าสุด"
                                                detail={fDateFull(data?.last_update)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="วันที่ได้รับการแก้ไข"
                                                detail={fDateFull(data?.resolution_date)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ContentDetail
                                                title="เจ้าหน้าที่"
                                                detail={data?.agent_id_friendlyname}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {data ? (
                <RequestForm
                    dataSource={data}
                    currentContacts={data?.contacts_list?.map((data) => ({
                        label: data.contact_id_friendlyname,
                        id: data.contact_id
                    }))}
                    contactList={contactList}
                    attachmentList={attachmentList}
                    upload={upload}
                    onUpload={onUpload}
                    onDeleteFile={onDeleteFile}
                    onDownload={onDownload}
                    reload={setReload}
                    loading={state.loading}
                    ticketCode={ticketCode}
                />
            ) : (
                <Preloading />
            )}
        </>
    );
}

export default observer(Content);

const columns = [
    { id: 'fileName', name: 'ชื่อเอกสาร', align: '' },
    { id: 'actions', name: '', align: '' }
];

const SATIS = [
    { id: '1', name: 'Very Satisfied' },
    { id: '2', name: 'Fairly Satisfied' },
    { id: '3', name: 'Rather dissatified' },
    { id: '4', name: 'Very dissatified' }
];

const RequestForm = observer((props) => {
    const navigate = useNavigate();
    const notify = useNotify();
    const { dataSource, currentContacts, contactList, reload, loading, ticketCode } = props;

    const formik = useFormik({
        initialValues: {
            public_log: EditorState.createEmpty(),
            contact_ids: currentContacts
        },
        onSubmit: (values, { setSubmitting, setFieldValue }) => {
            const data = { ...values };
            data.contact_ids =
                values.contact_ids?.length > 0
                    ? values.contact_ids?.map((contact) => contact.id)
                    : [0];
            data.public_log = values.public_log.getCurrentContent().hasText() ? draftToHtml(convertToRaw(values.public_log.getCurrentContent())) : undefined;
            updateRequest(dataSource?.ref, data)
                .then(() => {
                    notify();
                    setFieldValue('public_log', EditorState.createEmpty());
                    reload();
                    // navigate(`/portal/requests/${data.type}`);
                })
                .catch(() => {
                    notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' });
                })
                .finally(() => setSubmitting(false));
        }
    });

    const { isSubmitting, handleSubmit, values, setFieldValue } = formik;

    const validateEditor = () => values.public_log.getCurrentContent().hasText();

    const onRequestClosed = useRequestClosedModal();
    const onRequestReOpen = useRequestReOpenModal();

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                {loading ? (
                                    <Preloading />
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">ผู้ติดต่อ</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                disabled={
                                                    dataSource.operational_status !== 'ongoing'
                                                }
                                                multiple
                                                filterSelectedOptions
                                                id="tags-standard"
                                                options={contactList?.map((data) => ({ ...data }))}
                                                getOptionLabel={(option) => option?.label}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.id === value.id
                                                }
                                                // value={values.contact_ids}
                                                onChange={(_, value) =>
                                                    setFieldValue('contact_ids', value)
                                                }
                                                defaultValue={currentContacts}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="ผู้ติดต่อ"
                                                        placeholder="เพิ่มผู้ติดต่อ"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">บันทึกข้อความ</Typography>
                                        </Grid>
                                        {dataSource.operational_status === 'ongoing' && (
                                            <Grid item xs={12}>
                                                <Editor
                                                    editorStyle={{
                                                        border: '1px solid rgba(145, 158, 171, 0.24)',
                                                        borderRadius: '8px',
                                                        padding: '0 10px'
                                                    }}
                                                    toolbarHidden
                                                    editorState={values.public_log}
                                                    onEditorStateChange={(v) =>
                                                        setFieldValue('public_log', v)
                                                    }
                                                />
                                                {/* <TextField
                                                fullWidth
                                                label="บันทึกข้อความ"
                                                multiline
                                                rows={4}
                                                {...getFieldProps('public_log')}
                                            /> */}
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <PublicLog
                                                dataSource={dataSource?.public_log?.entries
                                                    .slice()
                                                    .reverse()}
                                            />
                                        </Grid>

                                        <AttachFilesTable
                                            ticketCode={ticketCode}
                                            enabled={dataSource.operational_status === 'ongoing'}
                                        />
                                        {/* <Grid item xs={12}>
                                            <Typography variant="h6">เอกสารแนบ</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableAllData
                                                columns={columns}
                                                dataSource={attachmentList}
                                                minTableWidth={0}
                                            >
                                                {(row, idx) => (
                                                    <TableRow key={idx}>
                                                        <TableCell component="th" scope="row">
                                                            <Button
                                                                href={`${
                                                                    process.env.REACT_APP_BASE_API
                                                                }/tickets/${ticketCode}/attachments/${
                                                                    row?.id
                                                                }/download?token=${
                                                                    getToken()?.token
                                                                }`}
                                                            >
                                                                {row?.contents?.filename}
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ width: 160 }}
                                                            align="right"
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                onClick={() =>
                                                                    onDeleteFile(row?.id)
                                                                }
                                                                disabled={
                                                                    dataSource.operational_status !==
                                                                    'ongoing'
                                                                }
                                                            >
                                                                ลบ
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableAllData>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="outlined"
                                                disableElevation
                                                component="label"
                                                loading={upload}
                                                disabled={
                                                    dataSource.operational_status !== 'ongoing'
                                                }
                                            >
                                                เพิ่มเอกสาร
                                                <input
                                                    id="file"
                                                    type="file"
                                                    hidden
                                                    name="file"
                                                    onChange={(e) => {
                                                        if (
                                                            typeof e.target.files?.[0] === 'object'
                                                        ) {
                                                            onUpload(e.target.files?.[0]);
                                                        }
                                                    }}
                                                />
                                            </LoadingButton>
                                        </Grid> */}
                                    </Grid>
                                )}

                                <Divider sx={{ my: 3 }} />

                                <Stack
                                    spacing={3}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MHidden width="mdDown">
                                        <LoadingButton
                                            sx={{
                                                width: {
                                                    md: '20%',
                                                    sm: '100%',
                                                    xs: '100%'
                                                }
                                            }}
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="inherit"
                                            loading={isSubmitting}
                                            onClick={() => navigate(-1)}
                                        >
                                            กลับ
                                        </LoadingButton>
                                    </MHidden>

                                    {dataSource.operational_status !== 'closed' && (
                                        <>
                                            {dataSource.operational_status === 'ongoing' && (
                                                <LoadingButton
                                                    sx={{
                                                        width: {
                                                            md: '20%',
                                                            sm: '100%',
                                                            xs: '100%'
                                                        }
                                                    }}
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    loading={isSubmitting}
                                                    disabled={
                                                        !validateEditor() &&
                                                        JSON.stringify(values.contact_ids) ===
                                                        JSON.stringify(currentContacts)
                                                    }
                                                >
                                                    บันทึก
                                                </LoadingButton>
                                            )}

                                            {dataSource.operational_status === 'resolved' && (
                                                <Stack
                                                    spacing={3}
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        sx={{
                                                            width: {
                                                                md: '20%',
                                                                sm: '100%',
                                                                xs: '100%'
                                                            }
                                                        }}
                                                        onClick={() => onRequestClosed(props)}
                                                    >
                                                        ปิดคำร้อง
                                                    </Button>
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        sx={{
                                                            width: {
                                                                md: '20%',
                                                                sm: '100%',
                                                                xs: '100%'
                                                            }
                                                        }}
                                                        onClick={() => onRequestReOpen(props)}
                                                    >
                                                        เปิดคำร้องใหม่
                                                    </Button>
                                                </Stack>
                                            )}
                                        </>
                                    )}
                                    {/* <LoadingButton
                                        sx={{
                                            width: {
                                                md: '15%',
                                                sm: '100%',
                                                xs: '100%'
                                            }
                                        }}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        disabled={
                                            !values.public_log &&
                                            JSON.stringify(values.contact_ids) ===
                                                JSON.stringify(currentContacts)
                                        }
                                    >
                                        บันทึก
                                    </LoadingButton> */}
                                </Stack>
                                <Stack mt={{ xs: 2, md: 0 }}>
                                    <MHidden width="mdUp">
                                        <LoadingButton
                                            sx={{
                                                width: {
                                                    xs: '100%'
                                                }
                                            }}
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="inherit"
                                            loading={isSubmitting}
                                            onClick={() => navigate(-1)}
                                        >
                                            กลับ
                                        </LoadingButton>
                                    </MHidden>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
});

RequestForm.propTypes = {
    dataSource: PropTypes.object,
    currentContacts: PropTypes.array,
    contactList: PropTypes.array,
    attachmentList: PropTypes.array,
    onUpload: PropTypes.func,
    onDownload: PropTypes.func,
    onDeleteFile: PropTypes.func,
    reload: PropTypes.func,
    upload: PropTypes.bool,
    loading: PropTypes.bool
};

function useRequestClosedModal() {
    const { modal } = useService();

    return useCallback(
        (props) => {
            modal.setModal({
                show: true,
                title: `ปิดคำร้อง ${props?.dataSource?.ref}`,
                content: <RequestClosedForm {...props} />,
                actions: 'disabled'
            });
        },
        [modal]
    );
}

function RequestClosedForm(props) {
    const notify = useNotify();
    const { dataSource, reload, ticketCode, onClose } = props;

    const formik = useFormik({
        initialValues: {
            status: 'closed',
            user_satisfaction: '1',
            user_comment: EditorState.createEmpty()
        },
        onSubmit: (values, { setSubmitting, setFieldValue }) => {
            const data = { ...values };
            data.user_comment = draftToHtml(convertToRaw(values.user_comment.getCurrentContent()));
            updateRequest(dataSource?.ref, data)
                .then(() => {
                    notify();
                    setFieldValue('user_comment', '');
                    reload();
                    onClose();
                })
                .catch(() => {
                    notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' });
                })
                .finally(() => setSubmitting(false));
        }
    });

    const { isSubmitting, handleSubmit, setFieldValue, getFieldProps, values } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" mb={1}>
                                    ความพิงพอใจในการแก้ปัญหา
                                </Typography>
                                <FormControl
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            md: '50%'
                                        }
                                    }}
                                >
                                    <Select
                                        labelId="user_satisfaction"
                                        id="user_satisfaction-select"
                                        // label="ระดับความเร่งด่วน"
                                        {...getFieldProps('user_satisfaction')}
                                    >
                                        {SATIS.map((data, idx) => (
                                            <MenuItem key={idx} value={data.id}>
                                                {data?.name?.toPascalCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">ความคิดเห็น</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Editor
                                    editorStyle={{
                                        border: '1px solid rgba(145, 158, 171, 0.24)',
                                        borderRadius: '8px',
                                        padding: '0 10px'
                                    }}
                                    toolbarHidden
                                    editorState={values.user_comment}
                                    onEditorStateChange={(v) => setFieldValue('user_comment', v)}
                                />
                            </Grid>

                            <AttachFilesTable ticketCode={ticketCode} />
                        </Grid>

                        <Divider sx={{ my: 3 }} />

                        <Actions isSubmitting={isSubmitting} onClose={onClose} />
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}

RequestClosedForm.propTypes = {
    dataSource: PropTypes.object,
    reload: PropTypes.func,
    ticketCode: PropTypes.string,
    onClose: PropTypes.func
};

function useRequestReOpenModal() {
    const { modal } = useService();

    return useCallback(
        (props) => {
            modal.setModal({
                show: true,
                title: `เปิดคำร้อง ${props?.dataSource?.ref} ใหม่`,
                content: <RequestReOpenForm {...props} />,
                actions: 'disabled'
            });
        },
        [modal]
    );
}

function RequestReOpenForm(props) {
    const notify = useNotify();
    const { dataSource, reload, ticketCode, onClose } = props;

    const RequestReOpen = Yup.object().shape({
        public_log: Yup.object()
            .test('has text', 'กรุณาบันทึกข้อความ', (value) => value.getCurrentContent().hasText())
            .required('กรุณาบันทึกข้อความ')
    });

    const formik = useFormik({
        initialValues: {
            status: 'assigned',
            public_log: EditorState.createEmpty()
        },
        validationSchema: RequestReOpen,
        onSubmit: (values, { setSubmitting, setFieldValue }) => {
            const data = { ...values };
            data.public_log = draftToHtml(convertToRaw(values.public_log.getCurrentContent()));

            updateRequest(dataSource?.ref, data)
                .then(() => {
                    notify();
                    setFieldValue('public_log', EditorState.createEmpty());
                    reload();
                    onClose();
                })
                .catch(() => {
                    notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' });
                })
                .finally(() => setSubmitting(false));
        }
    });

    const { isSubmitting, handleSubmit, setFieldValue, values, touched, errors } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">บันทึกข้อความ</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Editor
                                    editorStyle={{
                                        border: `1px solid ${touched.public_log && errors.public_log
                                            ? '#FF4842'
                                            : 'rgba(145, 158, 171, 0.24)'
                                            }`,
                                        borderRadius: '8px',
                                        padding: '0 10px'
                                    }}
                                    toolbarHidden
                                    editorState={values.public_log}
                                    onEditorStateChange={(v) => setFieldValue('public_log', v)}
                                />
                                {touched.public_log && errors.public_log && (
                                    <FormHelperText error sx={{ paddingLeft: 2 }}>
                                        {errors.public_log}
                                    </FormHelperText>
                                )}
                                {/* <TextField
                                                fullWidth
                                                label="บันทึกข้อความ"
                                                multiline
                                                rows={4}
                                                {...getFieldProps('public_log')}
                                            /> */}
                            </Grid>
                            <Grid item xs={12}>
                                <PublicLog
                                    dataSource={dataSource?.public_log?.entries.slice().reverse()}
                                />
                            </Grid>

                            <AttachFilesTable ticketCode={ticketCode} />
                        </Grid>

                        <Divider sx={{ my: 3 }} />

                        <Actions isSubmitting={isSubmitting} onClose={onClose} />
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}

RequestReOpenForm.propTypes = {
    dataSource: PropTypes.object,
    reload: PropTypes.func,
    ticketCode: PropTypes.string,
    onClose: PropTypes.func
};

Actions.propTypes = {
    isSubmitting: PropTypes.bool,
    onClose: PropTypes.func
};

function Actions({ isSubmitting, onClose }) {
    return (
        <Stack spacing={3} direction="row" justifyContent="space-between" alignItems="center">
            <LoadingButton
                sx={{
                    width: {
                        md: '15%',
                        sm: '100%',
                        xs: '100%'
                    }
                }}
                size="large"
                type="button"
                variant="contained"
                color="inherit"
                loading={isSubmitting}
                onClick={() => onClose()}
            >
                กลับ
            </LoadingButton>

            <LoadingButton
                sx={{
                    width: {
                        md: '15%',
                        sm: '100%',
                        xs: '100%'
                    }
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                บันทึก
            </LoadingButton>
        </Stack>
    );
}

AttachFilesTable.propTypes = {
    ticketCode: PropTypes.string,
    enabled: PropTypes.bool
};

function AttachFilesTable({ ticketCode, enabled = true }) {
    const notify = useNotify();
    const [attachmentList, setAttachmentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const [confirm, setConfirm] = useState({ open: false, file: null, loading: false });

    const getList = () => {
        setLoading(true);
        getAttachments(ticketCode)
            .then((res) => {
                setAttachmentList(res.data);
            })
            .finally(() => setLoading(false));
    };

    const onUpload = (file) => {
        setUpload(true);
        const formData = new FormData();
        formData.append('attachment', file);
        createAttachments(ticketCode, formData)
            .then(() => {
                notify({ message: 'อัปโหลดไฟล์สำเร็จ' });
                getList();
            })
            .catch(() => notify({ type: 'error', message: 'อัปโหลดไฟล์ไม่สำเร็จ' }))
            .finally(() => setUpload(false));
    };

    const onDeleteFile = (attachmentId) => {
        setConfirm({ ...confirm, loading: true });
        deleteAttachments(ticketCode, attachmentId)
            .then(() => {
                notify({ message: 'ลบไฟล์สำเร็จ' });
                getList();
            })
            .catch(() => notify({ type: 'error', message: 'ลบไฟล์ไม่สำเร็จ' }))
            .finally(() => setConfirm({ open: false, file: null, loading: false }));
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">เอกสารแนบ</Typography>
            </Grid>
            <Grid item xs={12}>
                {!loading ? (
                    <TableAllData columns={columns} dataSource={attachmentList} minTableWidth={0}>
                        {(row, idx) => (
                            <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                    <Button
                                        href={`${process.env.REACT_APP_BASE_API
                                            }/tickets/${ticketCode}/attachments/${row?.id
                                            }/download?token=${getToken()?.token}`}
                                    >
                                        {row?.contents?.filename}
                                    </Button>
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() =>
                                            setConfirm({ ...confirm, open: true, file: row })
                                        }
                                        disabled={!enabled}
                                    >
                                        ลบ
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableAllData>
                ) : (
                    <Preloading />
                )}
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    variant="outlined"
                    disableElevation
                    component="label"
                    loading={upload}
                    disabled={!enabled}
                >
                    เพิ่มเอกสาร
                    <input
                        id="file"
                        type="file"
                        hidden
                        name="file"
                        onChange={(e) => {
                            if (typeof e.target.files?.[0] === 'object') {
                                onUpload(e.target.files?.[0]);
                            }
                        }}
                    />
                </LoadingButton>
            </Grid>

            <Dialog
                open={confirm.open}
                onClose={() => setConfirm({ ...confirm, open: false, loading: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ต้องการลบ {confirm?.file?.contents?.filename} ใช่หรือไม่ ?
                </DialogTitle>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        color="inherit"
                        loading={confirm.loading}
                        onClick={() => setConfirm({ ...confirm, open: false, loading: false })}
                    >
                        ยกเลิก
                    </LoadingButton>

                    <LoadingButton
                        variant="contained"
                        loading={confirm.loading}
                        onClick={() => onDeleteFile(confirm?.file?.id)}
                    >
                        ยืนยัน
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
