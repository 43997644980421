import { authLogout, getToken } from '../services/auth';

const TOKEN = getToken()?.token;

export default function setupHTTPInterceptor(axiosAPI) {
    axiosAPI.interceptors.request.use(
        (config) => {
            if (TOKEN) {
                config.headers.Authorization = `Bearer ${TOKEN}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosAPI.interceptors.response.use(
        (response) => response,
        (error) => {
            switch (error?.response && error?.response?.data?.code) {
                case 'Forbidden.':
                    authLogout();
                    window.location.reload();
                    break;
                // case 'ACCESS_DENIED':
                //     window.location.replace(process.env.PUBLIC_URL + '/');
                //     break;
                default:
                    console.log(error?.response?.data);
                    break;
            }
            return Promise.reject(error);
        }
    );
}
