import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import B64ToImg from './B64ToImg';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
}));

// ----------------------------------------------------------------------

CardItem.propTypes = {
    icon: PropTypes.element,
    image: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    bgColor: PropTypes.string,
    fontColor: PropTypes.string,
    url: PropTypes.string
};

export default function CardItem({
    icon,
    image,
    title,
    subTitle,
    bgColor = '#F5F4F4',
    fontColor = '#1F1F1F',
    url = '#'
}) {
    return (
        <RootStyle
            component={url !== '#' ? RouterLink : null}
            to={url}
            sx={{ backgroundColor: bgColor, color: fontColor }}
        >
            {image?.data && (
                <Box style={{ marginRight: '20px' }}>
                    <B64ToImg {...image} width={50} />
                </Box>
            )}

            {icon && <Icon icon={icon} width={40} height={40} style={{ marginRight: '20px' }} />}
            <Box>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: {
                            lg: '18px'
                        }
                    }}
                    variant="h4"
                >
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 300,
                        fontSize: '13px'
                    }}
                >
                    {subTitle}
                </Typography>
            </Box>
        </RootStyle>
    );
}
