import * as React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Divider, Box } from '@mui/material';
import ContentDetail from '../../ContentDetail';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: `8px`,
    overflow: 'hidden',
    // '&:not(:last-child)': {
    //     borderBottom: 0
    // },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

Tree.propTypes = {
    dataSource: PropTypes.object
};

export default function Tree({ dataSource }) {
    if (!dataSource || Object.keys(dataSource)?.length < 1) {
        return (
            <Box display="flex" flex="row" justifyContent="center" sx={{ width: '100%' }}>
                ไม่มีข้อมูล
            </Box>
        );
    }

    return (
        <>
            {Object.keys(dataSource).map((key) => (
                <Box key={key}>
                    <Divider>{key}</Divider>
                    {dataSource[key].length > 0 &&
                        dataSource[key].map((data, idx) => (
                            <Accordion sx={{ mb: 1 }} key={idx}>
                                <AccordionSummary>
                                    <Typography>{data?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid container item xs={12} sm={4} spacing={2}>
                                            <Grid item xs={12}>
                                                <ContentDetail title="ประเภทคำถาม" detail={key} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="เรื่อง"
                                                    detail={data?.title}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="รหัสข้อผิดพลาด"
                                                    detail={data?.error_code}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="คีย์เวิร์ด"
                                                    detail={data?.key_words}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} sm={8} spacing={2}>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="เกี่ยวข้องกับ"
                                                    detail={
                                                        data?.domains?.length > 0 &&
                                                        data?.domains
                                                            ?.map((data) => data?.toPascalCase())
                                                            ?.join(', ')
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="สรุป"
                                                    detail={data?.summary}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ContentDetail
                                                    title="รายละเอียด"
                                                    detail={ReactHtmlParser(data?.description)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </Box>
            ))}
        </>
    );
}
