import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { observer } from 'mobx-react-lite';
import useService from '../../hooks/useService';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

function CustomizedDialogs() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { modal } = useService();

    const handleClose = () => {
        modal.closeModal();
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modal.initModal.show}
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {modal.initModal.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {React.cloneElement(modal.initModal.content, { onClose: handleClose })}
                </DialogContent>
                {modal.initModal.actions !== 'disabled' && (
                    <DialogActions>
                        {modal.initModal.actions ? (
                            React.cloneElement(modal.initModal.actions, { onClose: handleClose })
                        ) : (
                            <Button variant="contained" color="inherit" onClick={handleClose}>
                                ปิด
                            </Button>
                        )}
                    </DialogActions>
                )}
            </BootstrapDialog>
        </>
    );
}

export default observer(CustomizedDialogs);
