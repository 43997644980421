import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import HtmlParser from 'react-html-parser';
import { fDateFull } from '../../../utils/formatDate';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: `8px`,
    overflow: 'hidden',
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

PublicLog.propTypes = {
    dataSource: PropTypes.array
};

export default function PublicLog({ dataSource }) {
    if (!dataSource || dataSource?.length < 1) {
        return null;
    }

    return (
        <div>
            {dataSource?.map((data, idx) => (
                <Accordion key={idx}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{`${fDateFull(data?.date)} - ${data?.user_login}:`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="span" sx={{ whiteSpace: 'pre-wrap' }}>
                            {HtmlParser(data?.message_html)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
