// scroll bar
import './index.css';

import 'simplebar/src/simplebar.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
//
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import http from './utils/http';
import setupHTTPInterceptor from './utils/http.interceptors';

// Context
import ServiceContext, { services } from './contexts/service';

import './utils/toPascalCase';

import App from './App';

const buddhistEra = require('dayjs/plugin/buddhistEra');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(buddhistEra);
dayjs.extend(timezone);
dayjs.locale('th');
dayjs.tz.setDefault('Asia/Bangkok');

setupHTTPInterceptor(http);

services.auth.init();

// ----------------------------------------------------------------------

ReactDOM.render(
    <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ServiceContext.Provider value={services}>
                <App />
            </ServiceContext.Provider>
        </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
