import React from 'react';
import NotificationService from '../services/notification.mobx';
import AuthService from '../services/auth.mobx';
import ModalService from '../services/modal.mobx';
// import AppService from '../services/app.mobx';

export const services = {
    auth: new AuthService(),
    // app: new AppService(),
    modal: new ModalService(),
    notify: new NotificationService()
};

const ServiceContext = React.createContext(services);

export default ServiceContext;
