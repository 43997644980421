import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    // InputLabel,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Divider,
    TextField,
    Stack,
    Autocomplete,
    Typography,
    FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import PreLoading from '../../PreLoading';

import { getServiceFamilies, getServices, getServiceSubs } from '../../../services/service';
import { getContacts } from '../../../services/contact';
import { createRequest } from '../../../services/request';
import useNotify from '../../../hooks/useNotify';

import '../../../utils/toPascalCase';
// ----------------------------------------------------------------------

RequestForm.propTypes = {
    onSubmit: PropTypes.func
};

const IMPACTS = [
    { id: '1', name: 'A department' },
    { id: '2', name: 'A service' },
    { id: '3', name: 'A person' }
];

const URGENCIES = [
    { id: '1', name: 'critical' },
    { id: '2', name: 'high' },
    { id: '3', name: 'medium' },
    { id: '4', name: 'low' }
];

function RequestForm() {
    const navigate = useNavigate();
    const notify = useNotify();
    // const { familyId, serviceId, serviceSubId } = useParams();

    const state = useLocalObservable(() => ({
        loading: false,
        readyToSelect: true,
        familiesList: [],
        serviceList: [],
        serviceSubList: [],
        contactList: [],
        fetchFamiliesList() {
            state.setLoading(true);
            getServiceFamilies()
                .then(async (res) => {
                    runInAction(() => {
                        state.familiesList = res.data;
                    });
                })
                .finally(() => state.setLoading(false));
        },
        fetchServiceList(familyId) {
            if (!familyId) return
            state.readyToSelect = false;
            // state.setLoading(true);
            getServices(familyId)
                .then((res) => {
                    runInAction(() => {
                        state.serviceList = res.data.data;
                    });
                })
                .finally(() => {
                    state.readyToSelect = true;
                    // state.setLoading(false)
                });
        },
        fetchServiceSubList(familyId, serviceId) {
            if (!familyId || !serviceId) return
            // state.setLoading(true);
            state.readyToSelect = false;
            getServiceSubs(familyId, serviceId)
                .then((res) => {
                    runInAction(() => {
                        state.serviceSubList = res.data.data;
                    });
                })
                .finally(() => {
                    state.readyToSelect = true;
                    // state.setLoading(false)
                });
        },
        fetchContactList() {
            state.setLoading(true);
            getContacts()
                .then((res) => {
                    runInAction(() => {
                        const arr = res.data.data?.filter(
                            (data) => data?.status === 'active' && !data?.function?.includes('LINE')
                        );
                        state.contactList = arr;
                    });
                })
                .finally(() => state.setLoading(false));
        },
        setLoading(loading) {
            state.loading = loading;
        }
    }));

    useEffect(() => {
        async function fetchData() {
            await state.fetchFamiliesList();
            // await state.fetchServiceList();
            // await state.fetchServiceSubList(serviceId);
            await state.fetchContactList();
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const RequestSchema = Yup.object().shape({
        title: Yup.string().required('กรุณากรอกชื่อคำร้อง'),
        // description: Yup.string().required('กรุณากรอกรายละเอียด'),
        description: Yup.object()
            .test('has text', 'กรุณากรอกรายละเอียด', (value) => value.getCurrentContent().hasText())
            .required('กรุณากรอกรายละเอียด'),
        family_id: Yup.string().required('กรุณาเลือกหมวดหมู่บริการ'),
        service_id: Yup.string().required('กรุณาเลือกประเภทบริการ'),
        sub_service_id: Yup.string().required('กรุณาเลือกบริการ')
    });

    const formik = useFormik({
        initialValues: {
            // service_id: serviceId,
            // sub_service_id: serviceSubId,
            family_id: "",
            service_id: "",
            sub_service_id: "",
            impact: '1',
            urgency: '4',
            title: '',
            description: EditorState.createEmpty(),
            contact_ids: []
        },
        validationSchema: RequestSchema,
        onSubmit: (values, { setSubmitting }) => {
            // onSubmit();
            const data = { ...values };
            delete data.family_id;
            delete data.service_id;
            data.contact_ids =
                values.contact_ids?.length > 0
                    ? values.contact_ids?.map((contact) => contact.id)
                    : [0];
            data.type =
                state.serviceSubList?.find((sub) => sub.id === data.sub_service_id)
                    ?.request_type === 'service_request'
                    ? 'user_request'
                    : 'incident';
            data.description = draftToHtml(convertToRaw(values.description.getCurrentContent()));

            // console.log(data)
            createRequest(data)
                .then((res) => {
                    notify();
                    navigate(`/portal/request_update/${res?.data?.friendlyname}`);
                })
                .catch(() => notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' }))
                .finally(() => setSubmitting(false));
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values, setFieldValue } =
        formik;

    // const validateEditor = () => values.description.getCurrentContent().hasText();

    const onChangeFamily = (familyId) => {
        state.fetchServiceList(familyId);
        setFieldValue('family_id', familyId);
        setFieldValue('service_id', '');
        setFieldValue('sub_service_id', '');
    };

    const onChangeService = (serviceId) => {
        state.fetchServiceSubList(values?.family_id, serviceId);
        setFieldValue('service_id', serviceId);
        setFieldValue('sub_service_id', '');
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {state.loading ? (
                    <PreLoading />
                ) : (
                    <>
                        <Grid container spacing={3} sx={{ paddingBottom: '24px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" mb={1}>
                                    หมวดหมู่บริการ
                                </Typography>
                                <FormControl fullWidth error={errors.family_id}>
                                    {/* <InputLabel id="services">บริการ</InputLabel> */}
                                    <Select
                                        labelId="families"
                                        id="families-select"
                                        // label="บริการ"
                                        {...getFieldProps('family_id')}
                                        // value={values.service_id}
                                        onChange={(e) => onChangeFamily(e.target.value)}
                                        placeholder="เลือกหมวดหมู่บริการ"
                                        displayEmpty
                                    >
                                        <MenuItem disabled value="">
                                            เลือกหมวดหมู่บริการ
                                        </MenuItem>
                                        {state.familiesList?.length > 0 &&
                                            state.familiesList?.map((service, idx) => (
                                                <MenuItem key={idx} value={service?.id}>
                                                    {service?.name?.toPascalCase()}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.family_id && errors.family_id && (
                                        <FormHelperText error sx={{ paddingLeft: 2 }}>
                                            {errors.family_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" mb={1}>
                                    ประเภทบริการ
                                </Typography>
                                <FormControl fullWidth error={errors.service_id}>
                                    {/* <InputLabel id="services">บริการ</InputLabel> */}
                                    <Select
                                        labelId="services"
                                        id="services-select"
                                        // label="บริการ"
                                        {...getFieldProps('service_id')}
                                        // value={values.service_id}
                                        onChange={(e) => onChangeService(e.target.value)}
                                        displayEmpty
                                        disabled={!state.readyToSelect}
                                    >
                                        <MenuItem disabled value="">
                                            เลือกบริการ
                                        </MenuItem>
                                        {state.serviceList?.length > 0 &&
                                            state.serviceList?.map((service, idx) => (
                                                <MenuItem key={idx} value={service?.id}>
                                                    {service?.name?.toPascalCase()}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.service_id && errors.service_id && (
                                        <FormHelperText error sx={{ paddingLeft: 2 }}>
                                            {errors.service_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" mb={1}>
                                    บริการ
                                </Typography>
                                <FormControl fullWidth error={errors.sub_service_id}>
                                    {/* <InputLabel id="subcategory">ประเภทบริการ</InputLabel> */}
                                    <Select
                                        labelId="subcategory"
                                        id="subcategory-select"
                                        // label="ประเภทบริการ"
                                        {...getFieldProps('sub_service_id')}
                                        displayEmpty
                                        disabled={!state.readyToSelect}
                                    >
                                        <MenuItem disabled value="">
                                            เลือกประเภทบริการ
                                        </MenuItem>
                                        {state.serviceSubList?.length > 0 &&
                                            state.serviceSubList?.map((service, idx) => (
                                                <MenuItem key={idx} value={service?.id}>
                                                    {service?.name?.toPascalCase()}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {touched.sub_service_id && errors.sub_service_id && (
                                        <FormHelperText error sx={{ paddingLeft: 2 }}>
                                            {errors.sub_service_id}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" mb={1}>
                                    ผลกระทบ
                                </Typography>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="impact">ผลกระทบ</InputLabel> */}
                                    <Select
                                        labelId="impact"
                                        id="impact-select"
                                        // label="ผลกระทบ"
                                        {...getFieldProps('impact')}
                                    >
                                        <MenuItem disabled value="">
                                            เลือกผลกระทบ
                                        </MenuItem>
                                        {IMPACTS.map((data, idx) => (
                                            <MenuItem key={idx} value={data.id}>
                                                {data?.name?.toPascalCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" mb={1}>
                                    ระดับความเร่งด่วน
                                </Typography>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="Urgency">ระดับความเร่งด่วน</InputLabel> */}
                                    <Select
                                        labelId="urgency"
                                        id="urgency-select"
                                        // label="ระดับความเร่งด่วน"
                                        {...getFieldProps('urgency')}
                                    >
                                        {URGENCIES.map((data, idx) => (
                                            <MenuItem key={idx} value={data.id}>
                                                {data?.name?.toPascalCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" mb={1}>
                                    ชื่อคำร้อง
                                </Typography>
                                <TextField
                                    fullWidth
                                    type="text"
                                    // label="ชื่อคำร้อง"
                                    {...getFieldProps('title')}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" mb={1}>
                                    รายละเอียด
                                </Typography>
                                {/* <TextField
                                    fullWidth
                                    // label="รายละเอียด"
                                    multiline
                                    rows={4}
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                /> */}
                                <Editor
                                    editorStyle={{
                                        border: `1px solid ${touched.description && errors.description
                                            ? '#FF4842'
                                            : 'rgba(145, 158, 171, 0.24)'
                                            }`,
                                        borderRadius: '8px',
                                        padding: '0 10px'
                                    }}
                                    toolbarHidden
                                    editorState={values.description}
                                    onEditorStateChange={(v) => {
                                        setFieldValue('description', v);
                                    }}
                                />
                                {touched.description && errors.description && (
                                    <FormHelperText error sx={{ paddingLeft: 2 }}>
                                        {errors.description}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" mb={1}>
                                    ผู้ติดต่อ
                                </Typography>
                                <Autocomplete
                                    multiple
                                    filterSelectedOptions
                                    id="tags-standard"
                                    options={state.contactList}
                                    getOptionLabel={(option) => option?.friendlyname}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    // value={values.contact_ids}
                                    value={values.contact_ids}
                                    onChange={(_, value) => setFieldValue('contact_ids', value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // label="ผู้ติดต่อ"
                                            placeholder="เพิ่มผู้ติดต่อ"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                <Divider sx={{ my: 3 }} />

                <Stack
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <LoadingButton
                        sx={{
                            width: {
                                md: '15%',
                                sm: '100%',
                                xs: '100%'
                            }
                        }}
                        size="large"
                        type="button"
                        variant="contained"
                        loading={isSubmitting}
                        color="inherit"
                        onClick={() => navigate(-1)}
                    >
                        กลับ
                    </LoadingButton>

                    <LoadingButton
                        sx={{
                            width: {
                                md: '15%',
                                sm: '100%',
                                xs: '100%'
                            }
                        }}
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        บันทึก
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default observer(RequestForm);
