import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

// material
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '148px',
    boxShadow: 'none',
    padding: theme.spacing(4),
    color: '#1F1F1F',
    backgroundColor: '#F5F4F4',
    textDecoration: 'none',
    cursor: 'pointer'
}));

// ----------------------------------------------------------------------

ServiceCard.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    url: PropTypes.string
};

export default function ServiceCard({ icon, title, subTitle, url = '#' }) {
    const navigate = useNavigate();

    return (
        <RootStyle onClick={() => url && navigate(url)}>
            <Icon icon={icon} width={34} height={34} />
            <Typography sx={{ fontWeight: 500 }} variant="h5">
                {title}
            </Typography>
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
                {subTitle}
            </Typography>
        </RootStyle>
    );
}
