export function getImpact(value) {
    const data = {
        1: 'A department',
        2: 'A service',
        3: 'A person'
    };

    if (!data[value]) return null;

    return data[value];
}

export function getUrgency(value) {
    const data = {
        1: 'critical',
        2: 'high',
        3: 'medium',
        4: 'low'
    };

    if (!data[value]) return null;

    return data[value];
}

export function getPriority(value) {
    const data = {
        1: 'critical',
        2: 'high',
        3: 'medium',
        4: 'low'
    };

    if (!data[value]) return null;

    return data[value];
}

export function getSatis(value) {
    const data = {
        1: 'Very Satidfied',
        2: 'Fairly Satidfied',
        3: 'Rather dissatified',
        4: 'Very dissatified'
    };

    if (!data[value]) return null;

    return data[value];
}
