import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
// material
import { Box, Grid, Container, Typography, Divider } from '@mui/material';

// components
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import CardItem from '../components/CardItem';
import CardBack from '../components/CardBack';
import Breadcrumbs from '../components/BreadCrumbs';
import Preloading from '../components/PreLoading';

import { getServices, getServiceSubs } from '../services/service';
import { SERVICE_ICON } from '../utils/helpers';
// ----------------------------------------------------------------------

export default function Services() {
    return (
        <Page title="เพิ่มคำร้องขอรับบริการ">
            <Container maxWidth="xl">
                <Breadcrumbs
                    data={[
                        { name: 'หมวดหมู่บริการ', url: '/portal/families' },
                        { name: 'ประเภทบริการ' }
                    ]}
                />

                <MHidden width="smUp">
                    <Box sx={{ mt: 3 }}>
                        <CardBack />
                    </Box>

                    <Divider sx={{ my: 3 }} />
                </MHidden>

                <Box sx={{ my: 3 }}>
                    <Typography align="center" variant="h5">
                        เลือกประเภทบริการ
                    </Typography>
                </Box>

                <RenderContent />
            </Container>
        </Page>
    );
}

function RenderContent() {
    const { familyId } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getList = () => {
        setLoading(true);
        getServices(familyId)
            .then(async (res) => {
                const promises = [];
                const services = res.data.data ?? [];

                services.forEach((data) => promises.push(getSubService(data.id)));

                const resolved = await Promise.all(promises);
                resolved.forEach((data, i) => (services[i].count = data));
                setData(services);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    const getSubService = async (serviceId) => {
        setLoading(true);
        try {
            const resp = await getServiceSubs(familyId, serviceId, { limit: 1 });
            return resp.data?.total;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Preloading />;
    }

    return (
        <Grid container spacing={3}>
            <MHidden width="smDown">
                <Grid sx={{ minHeight: '109px' }} item xs={12} sm={6} md={3} lg={3}>
                    <CardBack />
                </Grid>
            </MHidden>

            {data.length > 0 &&
                data.map((data, idx) => (
                    <Grid sx={{ minHeight: '109px' }} key={idx} item xs={12} sm={6} md={3} lg={3}>
                        <CardItem
                            image={data?.icon?.data ? { ...data?.icon } : { ...SERVICE_ICON }}
                            bgColor={data?.count > 0 ? '#aa0000' : '#4B4B4B'}
                            fontColor="#fff"
                            title={data?.name}
                            subTitle={data?.description}
                            // subTitle={`${data?.description} ${
                            //     idx === 0 ? data?.description : 'xx'
                            // }`}
                            icon={data?.icon?.data}
                            url={
                                data?.count > 0
                                    ? `/portal/families/${familyId}/services/${data?.id}/service_subs`
                                    : ''
                            }
                        />
                    </Grid>
                ))}
        </Grid>
    );
}
