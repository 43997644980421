import React from 'react';
import PropTypes from 'prop-types';

const B64ToImg = ({ data, mimetype, width }) => (
    <img
        src={`data:${mimetype};base64,${data}`}
        style={{ minWidth: `${width}px`, width: `${width}px`, height: 'auto' }}
        alt=""
    />
);

B64ToImg.propTypes = {
    data: PropTypes.string,
    mimetype: PropTypes.string,
    width: PropTypes.number
};

export default B64ToImg;
