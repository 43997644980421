import * as Yup from 'yup';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Stack,
    TextField,
    IconButton,
    InputAdornment
    // Link,
    // Checkbox,
    // FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { authLogin, setToken } from '../../../services/auth';
import useNotify from '../../../hooks/useNotify';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const notify = useNotify();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('กรุณากรอกชื่อผู้ใช้'),
        password: Yup.string().required('กรุณากรอกรหัสผ่าน')
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const resp = await authLogin(values);
                setToken(resp.data);
                window.location.reload();
            } catch (error) {
                notify({ type: 'error', message: 'เข้าสู่ระบบไม่สำเร็จ' });
            }
            setSubmitting(false);
            // navigate('/dashboard', { replace: true });
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3} sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="text"
                        label="ชื่อผู้ใช้ (username)"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="รหัสผ่าน"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 2 }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox {...getFieldProps('remember')} checked={values.remember} />
                        }
                        label="Remember me"
                    />

                    <Link component={RouterLink} variant="subtitle2" to="#">
                        Forgot password?
                    </Link>
                </Stack> */}

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    เข้าสู่ระบบ
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
