import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Stack, Button } from '@mui/material';

import Girl from '../../../assets/images/girl.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '148px',
    boxShadow: 'none',
    padding: theme.spacing(2),
    color: '#FFFFFF',
    backgroundColor: '#F5F4F4',
    background: 'linear-gradient(to right, rgb(0 171 85), rgb(103 194 134));',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
}));

const ButtonStyle = styled(Button)({
    borderRadius: '25px',
    // backgroundColor: '#F5F4F4',
    // color: '#000',
    width: '150px',
    marginTop: '4px',
    marginLeft: '50px'
});

// ----------------------------------------------------------------------

RequestCard.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string
};

export default function RequestCard({ title, subTitle }) {
    return (
        <RootStyle>
            <img
                src={Girl}
                alt=""
                style={{
                    height: '95%',
                    width: 'auto',
                    position: 'absolute',
                    left: 20,
                    bottom: 0,
                    objectFit: 'contain'
                }}
            />
            <Stack sx={{ maxWidth: '220px' }}>
                <Typography sx={{ fontWeight: 500 }} variant="h5">
                    {title}
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.72, fontWeight: 300 }}>
                    {subTitle}
                </Typography>
                <ButtonStyle
                    color="inherit"
                    component={RouterLink}
                    to="/portal/request_create"
                    variant="contained"
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
                        เพิ่มคำร้อง
                    </Typography>
                </ButtonStyle>
            </Stack>
        </RootStyle>
    );
}
