import { useCallback } from 'react';
import useService from './useService';

const useNotify = () => {
    const { notify } = useService();

    return useCallback(
        ({
            message = 'บันทึกข้อมูลสำเร็จ',
            vertical = 'top',
            horizontal = 'center',
            duration = 1500,
            type = 'success'
        } = {}) => {
            notify.setNotify({
                vertical,
                horizontal,
                duration,
                type,
                message
            });
            // eslint-disable-next-line
        },
        [notify]
    );
};

export default useNotify;
