import React from 'react';
import { makeObservable, observable, action } from 'mobx';

export default class ModalService {
    initModal = {
        show: false,
        title: null,
        content: React.createElement('div'),
        actions: null
    };

    constructor() {
        makeObservable(this, {
            initModal: observable,

            setModal: action,
            clearModal: action,
            closeModal: action
        });
    }

    setModal(data) {
        this.initModal = data;
    }

    closeModal() {
        this.initModal.show = false;
    }

    clearModal() {
        this.initModal = {
            show: false,
            title: null,
            content: null,
            actions: null
        };
    }
}
