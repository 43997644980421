import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';

import dayjs from 'dayjs';

import { MHidden } from './@material-extend';

const dateFormat = 'DD-MM-BBBB';

DateRangePicker.propTypes = {
    onChange: PropTypes.func,
    initDates: PropTypes.array
};

export default function DateRangePicker({
    onChange = () => {},
    initDates = [new Date(), new Date()]
}) {
    const [value, setValue] = React.useState(initDates);

    const formatValue = (value) => [
        dayjs(value[0]).startOf('d').format(),
        dayjs(value[1]).endOf('d').format()
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <MHidden width="smUp">
                    <MobileDateRangePicker
                        startText="วันที่"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            onChange(formatValue(newValue));
                        }}
                        mask="__-__-____"
                        inputFormat={dateFormat}
                        // inputFormat="yyyy-MM-dd"
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField size="small" {...startProps} label="วันที่" />
                                <Box sx={{ mx: 2 }}> - </Box>
                                <TextField size="small" {...endProps} label="ถึงวันที่" />
                            </>
                        )}
                    />
                </MHidden>

                <MHidden width="smDown">
                    <DesktopDateRangePicker
                        startText="วันที่"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            onChange(formatValue(newValue));
                        }}
                        // mask="____-__-__"
                        // inputFormat="yyyy-MM-dd"
                        mask="__-__-____"
                        inputFormat={dateFormat}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField size="small" {...startProps} label="วันที่" />
                                <Box sx={{ mx: 2 }}> - </Box>
                                <TextField size="small" {...endProps} label="ถึงวันที่" />
                            </>
                        )}
                    />
                </MHidden>
            </Stack>
        </LocalizationProvider>
    );
}
