import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// material
import {
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
// components
import Breadcrumbs from '../components/BreadCrumbs';
import Page from '../components/Page';
import ContentDetail from '../components/ContentDetail';
import useService from '../hooks/useService';
import useNotify from '../hooks/useNotify';
import B64ToImg from '../components/B64ToImg';

import http from '../utils/http';
import { updateProfilePic, updateProfile } from '../services/auth';
import { FALLBACK_IMG } from '../utils/helpers';

export default function Profile() {
    return (
        <Page title="โปรไฟล์">
            <Container maxWidth="xl">
                <Breadcrumbs data={[{ name: 'โปรไฟล์' }]} />

                <Content />
            </Container>
        </Page>
    );
}

const Content = observer(() => {
    const { auth } = useService();
    const { userInfo } = auth;

    const notify = useNotify();
    const [upload, setUpload] = useState(false);

    const onUpload = (file) => {
        setUpload(true);
        const formData = new FormData();
        formData.append('avatar', file);
        updateProfilePic(formData)
            .then(() => {
                notify({ message: 'อัปโหลดไฟล์สำเร็จ' });
                auth?.fetchUserInfo();
            })
            .catch(() => notify({ type: 'error', message: 'อัปโหลดไฟล์ไม่สำเร็จ' }))
            .finally(() => setUpload(false));
    };

    return (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item lg={12} xl={6} sx={{ width: '100%' }}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Stack spacing={3} direction="column" alignItems="center">
                                    {userInfo?.picture?.data ? (
                                        <B64ToImg
                                            data={userInfo?.picture?.data}
                                            mimetype={userInfo?.picture?.mimetype}
                                            width={150}
                                        />
                                    ) : (
                                        <img
                                            src={FALLBACK_IMG}
                                            style={{
                                                minWidth: `150px`,
                                                width: `150px`,
                                                height: 'auto'
                                            }}
                                            alt=""
                                        />
                                    )}

                                    <LoadingButton
                                        variant="outlined"
                                        disableElevation
                                        component="label"
                                        loading={upload}
                                    >
                                        เลือกรูปภาพ
                                        <input
                                            id="file"
                                            type="file"
                                            hidden
                                            name="file"
                                            onChange={(e) => {
                                                if (typeof e.target.files?.[0] === 'object') {
                                                    onUpload(e.target.files?.[0]);
                                                }
                                            }}
                                        />
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentDetail
                                    title="ชื่อ - นามสกุล"
                                    detail={userInfo?.friendlyname}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ContentDetail title="องค์กร" detail={userInfo?.org_name} />
                            </Grid>
                            <Grid item xs={12}>
                                <ContentDetail title="อีเมล" detail={userInfo?.email} />
                            </Grid>
                            <Grid item xs={12}>
                                <ContentDetail title="ผู้จัดการ" detail={userInfo?.manager_name} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} xl={6} sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xl={12} sx={{ width: '100%' }}>
                        <ProfileForm />
                    </Grid>
                    <Grid item xl={12} sx={{ width: '100%' }}>
                        <ChangePasswordForm />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

const ProfileForm = observer(() => {
    const { auth } = useService();
    const [locations, setLocations] = useState([]);
    const notify = useNotify();

    const getLocations = async () => {
        const resp = await http.get('/locations');
        setLocations(resp.data);
    };

    useEffect(() => {
        getLocations();
    }, []);

    const ProfileSchema = Yup.object().shape({
        // title: Yup.string().required('กรุณากรอกชื่อคำร้อง'),
        // description: Yup.string().required('กรุณากรอกรายละเอียด')
    });

    const formik = useFormik({
        initialValues: {
            phone: auth.userInfo?.phone,
            function: auth.userInfo?.function,
            location_id: auth.userInfo?.location_id
        },
        validationSchema: ProfileSchema,
        onSubmit: (values, { setSubmitting }) => {
            // console.log(values);

            updateProfile(values)
                .then(() => {
                    notify();
                    auth.fetchUserInfo();
                })
                .catch(() => notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' }))
                .finally(() => setSubmitting(false));
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">แก้ไขข้อมูลส่วนตัว</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="tel"
                                    label="เบอร์โทรศัพท์"
                                    {...getFieldProps('phone')}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="location_id">สถานที่ทำงาน</InputLabel>
                                    <Select
                                        labelId="location_id"
                                        id="location_id-select"
                                        label="สถานที่ทำงาน"
                                        {...getFieldProps('location_id')}
                                    >
                                        <MenuItem disabled value="">
                                            เลือกสถานที่ทำงาน
                                        </MenuItem>
                                        {locations?.map((data, idx) => (
                                            <MenuItem key={idx} value={data?.id}>
                                                {data?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="ตำแหน่งงาน"
                                    {...getFieldProps('function')}
                                    error={Boolean(touched.function && errors.function)}
                                    helperText={touched.function && errors.function}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 3 }} />

                        <Stack
                            spacing={3}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <LoadingButton
                                loading={isSubmitting}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                บันทึก
                            </LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
            </Form>
        </FormikProvider>
    );
});

const ChangePasswordForm = () => {
    const notify = useNotify();
    const ChangePasswordSchema = Yup.object().shape({
        password_current: Yup.string()
            .required('กรุณากรอกรหัสผ่านปัจจุบัน')
            .min(8, 'ต้องมี 8 ตัวขึ้นไป'),
        password: Yup.string()
            .required('กรุณากรอกรหัสผ่านใหม่')
            .min(8, 'ต้องมี 8 ตัวขึ้นไป')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, //eslint-disable-line
                'ต้องมี 8 ตัวขึ้นไป, มีตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก, มีตัวเลข และอักษรพิเศษ'
            ),
        password_confirm: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string().oneOf([Yup.ref('password')], 'ยืนยันรหัสผ่านไม่ถูกต้อง')
        })
    });

    const formik = useFormik({
        initialValues: {
            password_current: '',
            password: '',
            password_confirm: ''
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            // console.log(values);
            delete values.password_confirm;
            updateProfile(values)
                .then(() => {
                    notify();
                    resetForm();
                })
                .catch(() => notify({ type: 'error', message: 'บันทึกข้อมูลไม่สำเร็จ' }))
                .finally(() => setSubmitting(false));
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">เปลี่ยรหัสผ่าน</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="รหัสผ่านปัจจุบัน"
                                    {...getFieldProps('password_current')}
                                    error={Boolean(
                                        touched.password_current && errors.password_current
                                    )}
                                    helperText={touched.password_current && errors.password_current}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="รหัสผ่านใหม่"
                                    {...getFieldProps('password')}
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="ยืนยันรหัสผ่าน"
                                    {...getFieldProps('password_confirm')}
                                    error={Boolean(
                                        touched.password_confirm && errors.password_confirm
                                    )}
                                    helperText={touched.password_confirm && errors.password_confirm}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 3 }} />

                        <Stack
                            spacing={3}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <LoadingButton
                                loading={isSubmitting}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                เปลี่ยรหัสผ่าน
                            </LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
            </Form>
        </FormikProvider>
    );
};
