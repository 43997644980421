import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: true,
});

export default http;
