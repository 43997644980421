import { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------
const STATUS = [
    { name: 'เปิด', value: 'ongoing' },
    { name: 'แก้ไขแล้ว', value: 'resolved' },
    { name: 'ปิด', value: 'closed' }
];

RequestStatus.propTypes = {
    onSearch: PropTypes.func
};

export default function RequestStatus({ onSearch }) {
    const [active, setActive] = useState(0);

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Box>สถานะ: </Box>
                {STATUS.map((status, idx) => (
                    <Button
                        key={idx}
                        variant={`${idx === active ? 'contained' : 'outlined'}`}
                        onClick={() => {
                            setActive(idx);
                            onSearch(status.value);
                        }}
                    >
                        {status.name}
                    </Button>
                ))}
            </Stack>
        </>
    );
}
