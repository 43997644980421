import { runInAction, makeObservable, observable, action } from 'mobx';
import * as authAPI from './auth';

export default class AuthService {
    userInfo = null;

    ready = false;
    isLoggedIn = false;

    constructor() {
        makeObservable(this, {
            ready: observable,
            userInfo: observable,
            isLoggedIn: observable,

            init: action,
            fetchUserInfo: action
        });
    }

    // eslint-disable-next-line
    async init() {
        // if (!getToken()) {
        //     return runInAction(() => {
        //         this.ready = true;
        //         this.isLoggedIn = false;
        //     });
        // }

        // try {
        //     const response = await authAPI.authME();
        //     runInAction(() => {
        //         this.isLoggedIn = true;
        //         this.userInfo = response.data;
        //     });
        // } catch (error) {
        //     if (error?.response?.data?.code === 'Forbidden.') {
        //         authAPI.authLogout();
        //     }
        // }

        // runInAction(() => {
        //     this.ready = true;
        // });
    }

    async fetchUserInfo() {
        const response = await authAPI.authME();
        runInAction(() => {
            this.userInfo = response?.data;
        });
    }

    login() {
        this.ready = true;
        this.isLoggedIn = true;
    }
}

function getToken() {
    return authAPI.getToken()?.token ?? null;
}
