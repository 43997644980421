import http from '../utils/http';

export function getServiceFamilies() {
    return http.get('/families');
}

export function getServices(familyId, params) {
    return http.get(`/families/${familyId}/services`, { params });
}

export function getServiceSubs(familyId, serviceId, params) {
    return http.get(`/families/${familyId}/services/${serviceId}/subs`, { params });
}
