// material
import { Box, Grid, Container, Typography, Divider } from '@mui/material';
import laptopIcon from '@iconify/icons-fa-solid/laptop';
// import thermometerIcon from '@iconify/icons-fa-solid/thermometer';
import questionMarkFill from '@iconify/icons-eva/question-mark-fill';
// components
import CardItem from '../components/CardItem';
import Page from '../components/Page';
import { HomeTable, RequestCard } from '../components/portal/home';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

export default function Home() {
    return (
        <Page title="หน้าหลัก">
            <Container maxWidth="xl">
                <Box sx={{ pb: 3 }}>
                    <Typography variant="h4">สวัสดี, ระบบช่วยเหลือยินดีต้อนรับ</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <RequestCard
                            title="ร้องขอการช่วยเหลือ"
                            subTitle="เลือกหัวข้อการช่วยเหลือแล้วกดยืนยันไปยังทีมช่วยเหลือของเรา"
                            icon={laptopIcon}
                        />
                    </Grid>
                    {/* <MHidden width="smDown">
                        <Grid item xs={12} sm={6} md={3}>
                            <ServiceCard
                                title="IT SERVICE"
                                subTitle="การบริการด้านเทคโนโลยี"
                                icon={laptopIcon}
                                url="/portal/services/1/service_subs"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <ServiceCard
                                title="ETC"
                                subTitle="การบริการด้านอื่น ๆ"
                                icon={thermometerIcon}
                                url="/portal/services/1/service_subs"
                            />
                        </Grid>
                    </MHidden> */}
                </Grid>

                <Divider sx={{ my: 3 }} />

                <MHidden width="smDown">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <HomeTable title="รายการคำร้อง (User Request)" type="user_request" />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <HomeTable title="รายการคำร้อง (Incident)" type="incident" />
                        </Grid>
                    </Grid>
                </MHidden>

                <MHidden width="smUp">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <CardItem
                                bgColor="#FFD564"
                                title="รายการคำร้อง (User Request)"
                                subTitle="ติดตามการช่วยเหลือที่ผ่านมา"
                                icon={laptopIcon}
                                url="/portal/requests/user_request"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <CardItem
                                bgColor="#FFD564"
                                title="รายการคำร้อง (Incident)"
                                subTitle="ติดตามการช่วยเหลือที่ผ่านมา"
                                icon={laptopIcon}
                                url="/portal/requests/incident"
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <CardItem
                                title="คำถามที่พบบ่อย"
                                subTitle="รวมปัญหาที่อาจเกิดขึ้น"
                                icon={questionMarkFill}
                                url="/portal/faqs"
                            />
                        </Grid>
                    </Grid>
                </MHidden>
            </Container>
        </Page>
    );
}
