import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';

import useService from '../../hooks/useService';
import { authLogout } from '../../services/auth';

// components
import MenuPopover from '../../components/MenuPopover';
//
// import account from '../../_mocks_/account';
import { FALLBACK_IMG } from '../../utils/helpers';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'หน้าหลัก',
        icon: homeFill,
        linkTo: '/portal/home'
    },
    {
        label: 'โปรไฟล์',
        icon: personFill,
        linkTo: '/portal/profile'
    }
];

// ----------------------------------------------------------------------

function AccountPopover() {
    const { auth } = useService();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onLogout = () => {
        // authLogout();
        // window.location.reload();
        window.location.href = process.env.REACT_APP_ITOP_LOGOUT;
    };

    const avatar = `data:${auth?.userInfo?.picture?.mimetype};base64,${auth?.userInfo?.picture?.data}`;

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar
                    src={auth?.userInfo?.picture?.data ? avatar : FALLBACK_IMG}
                    alt="photoURL"
                />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {auth.userInfo?.friendlyname}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {auth.userInfo?.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    >
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button onClick={onLogout} fullWidth color="inherit" variant="outlined">
                        ออกจากระบบ
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}

export default observer(AccountPopover);
